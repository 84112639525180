import React from 'react';
import { CardHeader, Col, Row } from 'reactstrap';
import {Filter} from "./Filter";
import {useSelector} from "react-redux";
import {list} from "../../store";

const Header = ({ total }) => {

  const amount = useSelector(list.selectors.amount)

  return (
    <CardHeader className={'bg-light'}>
      <Row className="align-items-center">
        <Col className={'col-auto'}><b className={'mr-2'}>Лиды</b><sup>{total}</sup></Col>
        <Col>
          <Filter />
        </Col>
        <Col className={`text-right`}>
          Итого: {amount} ₽
        </Col>
      </Row>
    </CardHeader>
  );
};

export {Header};
