import React from "react";
import {
    faFrog,
    faPlusCircle,
    faShoppingCart,
    faSpellCheck,
    faTachometerAlt,
    faTag
} from "@fortawesome/free-solid-svg-icons";
import {UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const icons = {
    coupon: {
        icon: faShoppingCart,
        title: 'Купон'
    },
    promo: {
        icon: faTag,
        title: 'Промокод'
    },
    fast: {
        icon: faTachometerAlt,
        title: 'Быстрокод'
    },
    admitad: {
        icon: faSpellCheck,
        title: 'Партнерка'
    },
    draft: {
        icon: faFrog,
        title: 'Черновик'
    },
    free: {
        icon: faPlusCircle,
        title: 'Free'
    }
};

export const OfferTypeIcon = ({target, type}) => {
    if (icons.hasOwnProperty(type)) {
        const {icon, title} = icons[type]
        return (
            <React.Fragment>
                <UncontrolledTooltip placement="top" target={target}>
                    <div dangerouslySetInnerHTML={{__html: title}}/>
                </UncontrolledTooltip>
                <FontAwesomeIcon id={target} icon={icon}/>
            </React.Fragment>
        );
    }
    return <React.Fragment>{'-'}</React.Fragment>
};
