import API from "../../../../../api";
import {clearFilters, setCompany, setItems, setLoading, setPagination, setRangeDataCreate, setType} from "./store";

export const fetchList = () => async (dispatch, getState) => {
    const state = getState()
    const {page, perPage, company, type, dataStart, dataEnd} = state['Leads/list']
    dispatch(setLoading(true));
    const response = await API.lead.index(page, perPage, company, type, dataStart, dataEnd);
    dispatch(setItems(response));
    dispatch(setLoading(false));
};

export const handlePagination = (eventType, tableData) => dispatch => {
    const {page, sizePerPage} = tableData;
    switch (eventType) {
        case 'pagination':
            dispatch(setPagination({page, perPage: sizePerPage}))
            break;
        default:
            console.log('Категории. Не обработано событие таблицы ' + eventType);
    }
};

export const onClearAllFilters = () => async dispatch => {
    dispatch(clearFilters());
};

export const onChangeLeadFilterCompany = selected => async dispatch => {
    dispatch(setCompany(selected));
};

export const onChangeLeadFilterType = selected => async dispatch => {
    dispatch(setType(selected));
};

export const onChangeLeadFilterRangeDataCreate = (dataStart, dataEnd) => async dispatch => {
    dispatch(setRangeDataCreate({dataStart, dataEnd}));
};
