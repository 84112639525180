import instance from '../axiosInstance';

export const lead = {
  async index(page, per_page, company, type, start, end ) {
    return await instance().get(`/leads`, {
      params: {
        page,
        per_page,
        company,
        type,
        start,
        end,
      }
    });
  }
};
