import {createSlice} from '@reduxjs/toolkit';
import {DEFAULT_PER_PAGE} from "../../../../../config";

const initialState = {
    leads: [],
    loading: false,
    total: 0,
    pageCount: 0,
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    companies: [],
    types: [],
    amount: 0,
    company: null,
    companySelected: null,
    type: null,
    typeSelected: null,
    dataStart: null,
    dataEnd: null,
};

const {actions, reducer} = createSlice({
    name: 'leadReleases',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setItems: (state, action) => {
            const { items = {}, pagination = {} } = action.payload
            const { leads = [], companies = [], types = [], amount = 0 } = items
            const { total, per_page, page, pages} = pagination
            state.leads = leads;
            state.companies = companies;
            state.types = types;
            state.amount = amount;
            state.total = total;
            state.pageCount = pages;
            state.page = page;
            state.perPage = per_page;
        },
        setPagination: (state, action) => {
            const {page = 1, perPage = DEFAULT_PER_PAGE} = action.payload;
            state.page = page;
            state.perPage = perPage;
        },
        setCompany: (state, action) => {
            const {payload} = action;
            state.company = convertSelectedToString(payload);
            state.companySelected = payload;
        },
        setType: (state, action) => {
            const {payload} = action;
            state.type = convertSelectedToString(payload);
            state.typeSelected = payload;
        },
        setRangeDataCreate: (state, action) => {
            const {dataStart, dataEnd} = action.payload;
            state.dataStart = dataStart;
            state.dataEnd = dataEnd;
        },
        clearFilters: (state) => {
            state.company = null
            state.companySelected = null
            state.type = null
            state.typeSelected = null
            state.dataStart = null
            state.dataEnd = null
        },
    }
});

export const {setLoading, setItems, setPagination, setCompany, setType, setRangeDataCreate, clearFilters} = actions;

const convertSelectedToString = selected => {
    let value = null;
    if (selected !== null) {
        let result = [];
        selected.map(item => {
            return result.push(item.value);
        });
        value = result.join(',');
    }
    return value;
};

export default reducer;




