const moduleState = state => state['Leads/list']

export const items = state => {
    const {leads} = moduleState(state)
    return leads
}

export const companies = state => {
    const {companies} = moduleState(state)
    return companies
}

export const types = state => {
    const {types} = moduleState(state)
    return types
}

export const amount = state => {
    const {amount} = moduleState(state)
    return amount
}

export const isLoading = state => {
    const {loading} = moduleState(state)
    return loading
}

export const pagination = state => {
    const {total, page, perPage} = moduleState(state)
    return {total, page, perPage}
}

export const filter = state => {
    const {
        company,
        type,
        dataStart,
        dataEnd,
    } = moduleState(state)
    return {
        company,
        type,
        dataStart,
        dataEnd,
    }
}

export const filterSelected = state => {
    const {
        phone,
        companySelected,
        typeSelected,
        managerSelected,
        dateStart,
        dateEnd,
    } = moduleState(state)
    return {
        phone,
        companySelected,
        typeSelected,
        managerSelected,
        dateStart,
        dateEnd
    }
}
