import auth from './ducks/auth';

import {leads} from '../modules/Leads'

import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';

const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
});

export const store = configureStore({
    reducer: {
        auth,
        ...leads
    },
    middleware,
    devTools: process.env.NODE_ENV !== 'production'
});
