import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
import {NavbarTop, Footer} from './components';
import AppContext from '../context/Context';
import {Leads} from "../modules";
import {HomePage, ProfilePage} from "./pages";
import {SettingsPage} from "./pages/SettingsPage";
import {Helmet} from "react-helmet";

const Root = ({location}) => {
    const {isFluid} = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className={isFluid ? 'container-fluid' : 'container'}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Менеджер eWay!</title>
            </Helmet>
            <div className="content">
                <NavbarTop/>
                <Switch>
                    <Route path="/" exact component={HomePage}/>
                    <Route path="/leads" exact component={Leads}/>
                    <Route path="/settings" component={SettingsPage}/>
                    <Route path="/profile" component={ProfilePage}/>
                </Switch>
                <Footer/>
            </div>
        </div>
    );
};

Root.propTypes = {location: PropTypes.object.isRequired};

export default Root;
