import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {list} from "../store";
import {Leads} from "../containers";

export const IndexPage = () => {

    const dispatch = useDispatch()

    const {page, perPage} = useSelector(list.selectors.pagination)
    const {company, type, dataStart, dataEnd} = useSelector(list.selectors.filter)

    useEffect(() => {
        dispatch(list.methods.fetchList())
    }, [dispatch, page, perPage, company, type, dataStart, dataEnd])

    return <Leads/>;
};
