import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field as FinalFormField } from 'react-final-form';

export const CheckboxField = ({ id, name, label }) => {
  return (
    <FormGroup className="form-check">
      <FinalFormField name={name}>
        {({ input, meta }) => (
          <React.Fragment>
            <Input id={id} {...input} type="checkbox" invalid={meta.error && meta.touched} />
            {meta.error && meta.touched && <small className={'text-danger'}>{meta.error}</small>}
          </React.Fragment>
        )}
      </FinalFormField>
      <Label for={id} check>
        {label}
      </Label>
    </FormGroup>
  );
};
