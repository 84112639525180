import React from 'react';
import {Card} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {list} from "../../store";
import {Header} from "../../components";
import {OfferTypeIcon, Table} from "../../../../shared";
import {phoneFormatter} from "../../../../helpers/table-column-formaters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

export const Leads = () => {
    const dispatch = useDispatch()

    const items = useSelector(list.selectors.items)
    const isLoading = useSelector(list.selectors.isLoading)
    const {total, page, perPage} = useSelector(list.selectors.pagination)

    const handleTableChange = (eventType, tableData) => dispatch(list.methods.handlePagination(eventType, tableData))

    const nameFormatter = cell => {
        if (cell !== null && typeof cell === 'object') {
            if (cell.hasOwnProperty('name')) {
                return cell.name
            }
        }
        return ''
    };

    const companyFormatter = cell => {
        if (cell !== null && typeof cell === 'object') {
            const {slug, name} = cell
            return <a href={`https://promokod.ati.su/shops/${slug}`} rel="noopener noreferrer" target='_blank'>{name}</a>
        }
        return ''
    };

    const offerTypeFormatter = (cel, {id, offer}) => {
        if (offer !== undefined && offer !== null && offer.hasOwnProperty('type')) {
            return <OfferTypeIcon target={`offer-type-icon-${id}`} type={offer.type}/>;
        }
        return <div>{''}</div>;
    };

    const statusFormatter = (cell) => {
        switch (cell) {
            case 3:
                return <FontAwesomeIcon style={{color: 'yellow'}} icon={faExclamationCircle}/>
            case 2:
                return <FontAwesomeIcon className={'text-danger'} icon={faExclamationCircle}/>
            case 1:
                return <FontAwesomeIcon className={'text-success'} icon={faExclamationCircle}/>
            default:
                return ''
        }
    };

    const columns = [
        {
            dataField: 'status',
            text: '',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
            formatter: statusFormatter,
        },
        {
            dataField: 'name',
            text: 'Имя',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'phone',
            headerClasses: 'border-0',
            text: 'Телефон',
            classes: 'border-0 py-2 align-middle',
            formatter: phoneFormatter,
        },
        {
            dataField: 'city',
            text: 'Город',
            formatter: nameFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'offer',
            text: 'Предложение',
            formatter: nameFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
        },
        {
            dataField: 'company',
            text: 'Компания',
            formatter: companyFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle',
        },
        {
            dataField: 'createdAt',
            text: 'Время',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'type',
            text: 'Тип',
            formatter: offerTypeFormatter,
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },
        {
            dataField: 'price',
            text: 'Стоимость',
            headerClasses: 'border-0',
            classes: 'border-0 py-2 align-middle'
        },

    ];

    return (
        <Card className="mb-3">
            <Header total={total}/>
            <Table
                columns={columns}
                items={items}
                loading={isLoading}
                page={page}
                perPage={perPage}
                total={total}
                handleTableChange={handleTableChange}
            />

        </Card>
    );
}
