import React from 'react';
import {Col, Form, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {DataRangePicker} from './DataRangePicker';
import {FilterDropdown} from "../../../../../shared/FilterDropdown";
import {list} from "../../../store";
import {
    onChangeLeadFilterCompany,
    onChangeLeadFilterRangeDataCreate,
    onChangeLeadFilterType,
    onClearAllFilters
} from "../../../store/ducks/list/methods";

export const Filter = () => {
    const dispatch = useDispatch();

    const companies = useSelector(list.selectors.companies)
    const types = useSelector(list.selectors.types)

    const {
        phone,
        companySelected,
        typeSelected,
        managerSelected,
        dateStart,
        dateEnd,
    } = useSelector(list.selectors.filterSelected);

    const countFilterSelected = () => {
        let result = 0
        if (phone) {
            result++
        }
        if (companySelected) {
            result++
        }
        if (typeSelected) {
            result++
        }
        if (managerSelected) {
            result++
        }
        if (dateStart) {
            result++
        }
        if (dateEnd) {
            result++
        }
        return result
    }

    const clearAllFilters = () => {
        dispatch(onClearAllFilters())
    }

    const onChangeCompany = selected => {
        dispatch(onChangeLeadFilterCompany(selected));
    };

    const onChangeType = selected => {
        dispatch(onChangeLeadFilterType(selected));
    };

    const onChangeDataCreatePeriod = (start, end) => {
               dispatch(
            onChangeLeadFilterRangeDataCreate(
                start.format('DD.MM.YYYY'),
                end.format('DD.MM.YYYY')
            )
        );
    };

    const onClearDataCreatePeriod = () => {
        dispatch(onChangeLeadFilterRangeDataCreate(null, null));
    };

    return (
        <React.Fragment>
            <Row noGutters>
                <Col xs={'auto'}>
                    <Form inline className="search-box">
                        <div style={{marginTop: -8, marginBottom: -8}}>
                            <Select
                                isMulti
                                value={companySelected}
                                className={'m-1  search-input'}
                                onChange={onChangeCompany}
                                options={companies}
                                placeholder={'Компания'}
                                isClearable
                            />
                            <FontAwesomeIcon
                                icon="search"
                                className="position-absolute text-400 search-box-icon"
                            />
                        </div>
                    </Form>
                </Col>
                <Col xs={'auto'}>
                    <FilterDropdown countFilter={countFilterSelected()} onClearClick={clearAllFilters}>
                        <Select
                            isMulti
                            value={typeSelected}
                            className={'m-1'}
                            onChange={onChangeType}
                            options={types}
                            placeholder={'Тип'}
                            isClearable
                        />
                        <DataRangePicker
                            label="Дата"
                            startData={dateStart}
                            endData={dateEnd}
                            onChangeDataPeriod={onChangeDataCreatePeriod}
                            onClearDataPeriod={onClearDataCreatePeriod}
                        />
                    </FilterDropdown>
                </Col>
            </Row>
        </React.Fragment>
    );
};
