import listReducer from './ducks/list'

import * as list from './ducks/list'

export {list}

export default {
    'Leads/list': listReducer,
}



